export default {
  basePath: '/',
  env: 'prd',
  menuBasePath: 'https://backoffice-internal.prd.ueno.it/header',
  remoteAppShellBasePath: 'https://backoffice-internal.prd.ueno.it/app-shell',
  sharedBasePath:
    'https://kongcloud-private.ind-prod-sae1.ueno.com.py/bo/mfes/shared',
  api: {
    url: 'https://minegocio-bff.itti.digital',
    jwt: 'authorization',
  },
}
