export default {
  basePath: '/',
  env: 'stg',
  // menuBasePath: 'http://localhost:8080/header',
  // remoteAppShellBasePath: 'http://localhost:8080/app-shell',
  sharedBasePath:
    'https://kongcloud-private.ind-dev-sae1.ueno.com.py/bo/mfes/shared',
  api: {
    url: '',
    jwt: 'authorization',
    app: 'APP',
  },
}
