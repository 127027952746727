export default {
  basePath: '/',
  env: 'local',
  menuBasePath: '/header',
  remoteAppShellBasePath: '/app-shell',
  sharedBasePath:
    'https://platform-bo-shared-dev-sae-1-ttwwx.ind-dev-sae1.ueno.com.py/shared',
  api: {
    url: 'https://dev-minegocio-bff.itti.digital',
    jwt: 'authorization',
  },
}
