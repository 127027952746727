export default {
  basePath: '/',
  env: 'stg',
  menuBasePath: '/header',
  sharedBasePath: '',
  remoteAppShellBasePath: '/app-shell',
  api: {
    url: '/api',
    jwt: 'authorization',
    app: 'APP',
  },
}
